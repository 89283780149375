<template>
  <div>
    <RMPageHeader v-if="!error">
      <template v-slot:header v-if="myStudent">
        {{ myStudent.name }}
        <small>
          <b-link
            v-on:click="navigateToMyStudentPage()"
          >
            ({{ $t("mentorship.student") }})
          </b-link>
        </small>
      </template>
    </RMPageHeader>
    <div class="row" v-if="error">
      {{ this.error }}
    </div>
    <b-tabs v-else>
      <AssignmentsList
        v-if="myStudent"
        entity="myStudents"
        :entity-id="myStudent.id"
      />
    </b-tabs>
  </div>
</template>

<script>
import {mentorshipService} from "@/_services/mentorship.service";
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import AssignmentsList from "@/pages/mentorship/assignments/AssignmentsList.vue";
import {getMyStudentsRoute} from "@/_helpers/routes_helper";

export default {
  name: "MyStudent",
  components: {AssignmentsList, RMPageHeader},
  data() {
    return {
      loadingStudent: false,
      loadingError: false,
      error: "",
      myStudent: null
    }
  },
  computed: {
    pageTitle() {
      if(this.myStudent !== null && "name" in this.myStudent) {
        return `${this.myStudent.name} <small>(${this.$t("mentorship.student")})</small>`
      }
      return this.$t("mentorship.student")
    }
  },
  methods: {
    navigateToMyStudentPage() {
      this.$router.push(getMyStudentsRoute());
    },
    loadMyStudent() {
      this.loadingStudent = true;

      mentorshipService.getMyStudent(this.$route.params.studentId).then((data) => {
        this.myStudent = data.data;
        this.loadingStudent = false;
      }).catch((error) => {
        this.loadingError = true;
        this.error = error;
      })
    }
  },
  created() {
    this.loadMyStudent();
  },
}
</script>